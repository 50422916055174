.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container{
  display:flex;
  width:330px;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .container {
    width:78vh;
  }
}

.circle{
  width: 45px;
  height: 45px;
  border: 4px solid white;
  border-radius: 50%;
  position:relative;
  opacity: 0.6;
}

.left-bar{
  width:4px;
  height:26px;
  background:white;
  position:absolute;
  left:13px;
  top: 10px;
}

.right-bar{
  width:4px;
  height:26px;
  background:white;
  position:absolute;
  right: 13px;
  top: 10px;
}

.button{
  cursor:pointer;
  width: 100px;
  height: 100px;
  border: none;
  margin: 3px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 600px) {
  .button {
    width: 25vh;
    height: 25vh;
    min-width: 150px;
    min-height: 150px;
  }
}

.brown {
  background: linear-gradient(to bottom right, rgb(224, 33, 33), rgba(211, 75, 42, 0.411));
}

.purple {
  background: linear-gradient(to bottom right, purple, rgb(49, 5, 49));
}

.orange {
  background: linear-gradient(to bottom right, orange, rgba(187, 124, 6, 0.753));
}

.blue {
  background: linear-gradient(to bottom right, blue, rgba(4, 4, 128, 0.644));
}

.green {
  background: linear-gradient(to bottom right, green, rgba(2, 83, 2, 0.548));
}

.yellow {
  background: linear-gradient(to bottom right, yellow,rgba(148, 148, 6, 0.671));
}

.pink{
  background: linear-gradient(to bottom right, pink, rgba(190, 117, 129, 0.808));
}

.grey{
  background: linear-gradient(to bottom right, grey, rgba(68, 67, 67, 0.534));
}

.peagreen{
  background: linear-gradient(to bottom right, rgb(71, 233, 71), rgba(36, 117, 36, 0.63));
}

.img-studio {
  width: 80%;
}